<template>
  <div>
    <v-card id="review-list">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <!-- Date range -->
          <v-menu
            v-model="isDateMenuOpen"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :append-icon="icons.mdiCalendar"
                :value="dateRangeText"
                readonly
                dense
                outlined
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              color="primary"
              :first-day-of-week="1"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                block
                @click="dateRangeChange()"
              >
                {{ t('Set Dates') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

        <div class="row text-right pb-6">
          <div class="col-sm-4 col-12">
            <!-- filter account -->
            <v-combobox
              v-model="branchFilters"
              :items="branches"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Branch')"
              :class="{ 'review-list-branch': $vuetify.breakpoint.smAndUp }"
            ></v-combobox>
          </div>

          <div class="d-flex align-center col-sm-5 col-12">
            <!-- search review -->
            <v-tooltip
              :left="$vuetify.breakpoint.smAndUp"
              :top="$vuetify.breakpoint.xsOnly"
              :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
              :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
              :nudge-right="$vuetify.breakpoint.xsOnly ? 100 : 0"
              max-width="250"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar
                  size="30"
                  class="mr-3 hover-pointer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="25">
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-avatar>
              </template>
              <h3 class="my-2">
                {{ t('How to use the search bar') }}
              </h3>
              <p class="mb-1">
                {{ t('You can search using') }}:
              </p>
              <ul class="mb-2">
                <li>
                  {{ t('Receipt No.') }}
                </li>
                <li>
                  {{ t('Name') }}
                </li>
                <li>
                  {{ t('Contact No.') }}
                </li>
              </ul>
            </v-tooltip>
            <v-text-field
              v-model="searchText"
              :append-icon="icons.mdiMagnify"
              single-line
              dense
              outlined
              hide-details
              :placeholder="t('Search Review')"
              :class="{ 'review-list-search': $vuetify.breakpoint.mdAndUp }"
            ></v-text-field>
          </div>

          <div class="col-sm-3 col-12">
            <v-btn
              block
              color="primary"
              @click="searchReviews"
            >
              <v-icon
                class="pr-2"
                size="30"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              Search
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="review-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              class="list-row-selection"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="reviews"
        :items-per-page="100"
        :loading="loading"
        mobile-breakpoint="0"
        hide-default-footer
        :disable-sort="true"
        class="text-no-wrap"
      >
        <!-- Actions -->
        <template #[`item.actions`]="{item}">
          <div class="d-flex align-center justify-center">
            <!-- Edit -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :to="{
                    name: 'apps-review-edit',
                    params: { id: item.id }
                  }"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <!-- Void -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="voidableReview = item; isVoidDialogVisible = true"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Void</span>
            </v-tooltip>
          </div>
        </template>

        <!-- Receipt Number -->
        <template #[`item.receipt_number`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-receipt-preview', params: { id: item.id } }"
          >
            {{ item.receipt_number }}
          </router-link>
        </template>
      </v-data-table>

      <v-card-text class="pt-5">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <div class="d-flex align-center">
              <v-select
                v-model="tablePagination.per"
                :items="listLengthOptions"
                single-line
                outlined
                dense
                hide-details
                class="review-list-row-selection"
                @input="listLengthChange()"
              ></v-select>
              <div class="pl-3">
                {{ t('Rows') }}
              </div>
            </div>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              class="list-row-selection"
              @input="pageChange()"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="text-right">
      <v-btn
        color="primary"
        :loading="printLoading"
        :disabled="printLoading"
        :block="$vuetify.breakpoint.xsOnly"
        class="review-button mt-5"
        @click="printReport()"
      >
        <v-icon
          v-if="!printLoading"
          size="18"
          class="me-2"
        >
          {{ icons.mdiPrinter }}
        </v-icon>
        <span>{{ t('Print Report') }}</span>
      </v-btn>
    </div>

    <!-- Void dialog -->
    <v-dialog
      v-model="isVoidDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title class="d-flex align-center mv-4">
          Void {{ voidableReview.name }}'s Review?
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isVoidDialogVisible = false"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="can('write', 'ReportActions')">
          This review will be voided.
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="can('write', 'ReportActions')"
            color="error"
            block
            :loading="voidLoading"
            :disabled="voidLoading"
            class="mt-3"
            @click="voidReview()"
          >
            Yes, remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiCalendar,
  mdiPrinter,
  mdiMagnify,
  mdiClose,
  mdiFileDocumentOutline,
  mdiHelpCircleOutline,
  mdiContentSave,
} from '@mdi/js'
import {
  onMounted,
  onUnmounted,
  ref,
  inject,
  computed,
} from '@vue/composition-api'
import reviewStoreModule from './reviewStoreModule'

export default {
  components: {
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const can = inject('can')

    const REVIEW_APP_STORE_MODULE_NAME = 'app-review'

    if (!store.hasModule(REVIEW_APP_STORE_MODULE_NAME)) {
      store.registerModule(REVIEW_APP_STORE_MODULE_NAME, reviewStoreModule)
    }

    // Table Handlers
    const tableColumns = computed(() => (
      [
        { text: t('Actions'), value: 'actions', align: can('read', 'ReportSummaries') ? 'center' : ' d-none' },
        { text: t('Date Issued'), value: 'created_at', align: 'center' },
        { text: t('Receipt'), value: 'receipt_number' },
        { text: t('Name'), value: 'name' },
        { text: t('Contact No.'), value: 'contact_number' },
        { text: t('Star Rating'), value: 'rating', align: 'center' },
        { text: t('Suggestions'), value: 'suggestions' },
      ]
    ))

    // Properties
    const reviews = ref([])
    const searchText = ref('')
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const loading = ref(false)
    const voidLoading = ref(false)
    const printLoading = ref(false)
    const listLengthOptions = ref([20, 50, 100])
    const branchFilters = ref([])
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const dateRange = ref([`${yearNow}-${monthNow}-${dayNow}`])
    const isDateMenuOpen = ref(false)
    const isVoidDialogVisible = ref(false)
    const voidableReview = ref({})

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }

    // Computed
    const dateRangeText = computed(() => {
      if (Date.parse(dateRange.value[0]) > Date.parse(dateRange.value[1])) {
        const [start, end] = dateRange.value
        dateRange.value[0] = end
        dateRange.value[1] = start
      }

      const startDateText = formatDate(dateRange.value[0])
      const showEndDate = dateRange.value[0] !== dateRange.value[1] && formatDate(dateRange.value[1]) !== null
      const endDateText = showEndDate ? ` - ${formatDate(dateRange.value[1])}` : ''

      return `${startDateText}${endDateText}`
    })

    const branches = computed(() => store.state.branches.filter(b => b.active))

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchReviews = () => {
      store
        .dispatch('app-review/fetchReviews', {
          page: tablePagination.value.current_page,
          per: tablePagination.value.per,
          search_text: searchText.value,
          branch_ids: branchFilters.value.map(branch => branch.id),
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
        })
        .then(response => {
          const { data, pagination } = response.data
          reviews.value = data
          reviews.value.forEach(field => {
            Object.keys(field).forEach(key => {
              if (field[key] === null) field[key] = 'NULL'
            })
          })
          tablePagination.value = pagination
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching reviews. Please refresh!')
        })
    }
    const printReport = () => {
      printLoading.value = true
      store
        .dispatch('app-review/printReport', {
          search_text: searchText.value,
          branch_ids: branchFilters.value.map(branch => branch.id),
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
        })
        .then(response => {
          const url = window.URL.createObjectURL(response)
          const a = document.createElement('a')
          a.href = url
          a.download = `review(${dateRangeText.value}).pdf`
          document.body.appendChild(a)
          a.click()
          a.remove()
          printLoading.value = false
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while printing report. Please refresh!')
        })
    }
    const searchReviews = () => {
      isLoading()
      fetchReviews()
    }
    const pageChange = () => {
      isLoading()
      fetchReviews()
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      isLoading()
      fetchReviews()
    }
    const dateRangeChange = () => {
      isDateMenuOpen.value = false
      fetchReviews()
    }
    const voidReview = () => {
      voidLoading.value = true
      store
        .dispatch('app-review/voidReview', { id: voidableReview.value.id })
        .then(response => {
          snackbarService.success(response.data.message)
          fetchReviews()
          isVoidDialogVisible.value = false
          voidLoading.value = false
        })
        .catch(error => {
          voidLoading.value = false
          snackbarService.error(error.data.message || 'Something went wrong while voiding review. Please refresh!')
        })
    }

    onMounted(() => {
      fetchReviews()
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(REVIEW_APP_STORE_MODULE_NAME)) store.unregisterModule(REVIEW_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      can,
      loading,
      reviews,
      tablePagination,
      tableColumns,
      listLengthOptions,
      fetchReviews,
      pageChange,
      listLengthChange,
      dateRangeChange,
      formatDate,
      searchReviews,
      searchText,
      branchFilters,
      branches,
      dateRange,
      isDateMenuOpen,
      dateRangeText,
      isVoidDialogVisible,
      voidLoading,
      voidableReview,
      voidReview,
      printLoading,
      printReport,

      icons: {
        mdiPlus,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiCalendar,
        mdiPrinter,
        mdiMagnify,
        mdiClose,
        mdiContentSave,
        mdiFileDocumentOutline,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #review-list {
    .review-list-row-selection {
      max-width: 5.3rem;
    }

    .review-list-branch {
      max-width: 22rem;
    }
  }

  .review-button {
    width: 251px;
  }

  ::v-deep .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.loading-color {
    background: #d3d3d3 !important;
    color: #eee !important;
  }

  .dark-divider {
    border-right: 1px solid rgba(231, 227, 252, 0.14) !important;
  }

  .light-divider {
    border-right: 1px solid rgba(94, 86, 105, 0.14);
  }
</style>
